<!-- Secondary Background -->
<div *ngIf="fuseConfig.layout.style != 'horizontal-layout-1'" class="navbar-header noPrint" [ngClass]="fuseConfig.layout.navbar.customSecondaryBackground ? null : fuseConfig.layout.navbar.secondaryBackground" [style.background-color]="fuseConfig.layout.navbar.customSecondaryBackground" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="logo w-100-p">
    <a [routerLink]="['/pages/home']">
      <img *ngIf="!fuseConfig?.layout?.navbar?.folded" class="directorLogo" [src]="fuseConfig.directorLogo">
      <img *ngIf="fuseConfig?.layout?.navbar?.folded" [src]="fuseConfig.foldedLogo" alt="" style="max-height: 64px;">
    </a>
  </div>
</div>
<!-- Primary Background -->
<div class="navbar-content" style="height: 100%" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}" [ngClass]="fuseConfig.layout.navbar.customPrimaryBackground ? null : fuseConfig.layout.navbar.primaryBackground" [style.background-color]="fuseConfig.layout.navbar.customPrimaryBackground" [style.color]="fuseConfig.layout.navbar.customPrimaryForeground">
  <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  <ng-container *ngIf="!fuseConfig?.layout?.navbar?.folded">
    <div class="text-center">
      <div id="sidebar-ad" class="mat-elevation-z4 mx-auto my-8 p-8 border-radius-4 fuse-white" style="display:none; width: fit-content"></div>
    </div>
  </ng-container>
</div>
